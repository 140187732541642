/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0148c4ca3456ea72-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4184cf5f191b83db-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28cea0425961ab58-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4c759cba7a5f011-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/de555e6bc944c11a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/791334aa8b2b679f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a2411df190893074-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/60d74363e715829c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb37c9fe4776ea97-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc381c5935034aa5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f565fad065ab4cf3-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f9946f06d70a1b5b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1f5f89450ecd78c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ebcf93a611f94ec0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec50b434b597bc61-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6be22a85bef54f08-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9491f41002d34397-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/afba6ba6bf9157e8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/be843c5b0882ad66-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/67aea609c6135b3b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/09b62d668edb05d1-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/10446295ae7f2657-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b6ecfd81ed9f83c9-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_b17199';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/08404bcfb1dae67a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_b17199';src: local("Arial");ascent-override: 101.44%;descent-override: 27.22%;line-gap-override: 0.00%;size-adjust: 101.04%
}.__className_b17199 {font-family: '__IBM_Plex_Sans_b17199', '__IBM_Plex_Sans_Fallback_b17199';font-style: normal
}.__variable_b17199 {--font-ibm: '__IBM_Plex_Sans_b17199', '__IBM_Plex_Sans_Fallback_b17199'
}

/* latin-ext */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/becddef29ef4244a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/195aec2786dd6554-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/becddef29ef4244a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/195aec2786dd6554-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/becddef29ef4244a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/195aec2786dd6554-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/becddef29ef4244a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_378b28';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/195aec2786dd6554-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sora_Fallback_378b28';src: local("Arial");ascent-override: 85.29%;descent-override: 25.50%;line-gap-override: 0.00%;size-adjust: 113.73%
}.__className_378b28 {font-family: '__Sora_378b28', '__Sora_Fallback_378b28';font-style: normal
}.__variable_378b28 {--font-sora: '__Sora_378b28', '__Sora_Fallback_378b28'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/19c6f7bc71ae6455-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a3f10806bfaadecd-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cd31ca627c8e47ef-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e09ef39f9e6d759f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0939ccbd88d29479-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/19c6f7bc71ae6455-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a3f10806bfaadecd-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cd31ca627c8e47ef-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e09ef39f9e6d759f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Overpass_Mono_6370eb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0939ccbd88d29479-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Overpass_Mono_Fallback_6370eb';src: local("Arial");ascent-override: 63.27%;descent-override: 27.44%;line-gap-override: 0.00%;size-adjust: 139.55%
}.__className_6370eb {font-family: '__Overpass_Mono_6370eb', '__Overpass_Mono_Fallback_6370eb';font-style: normal
}.__variable_6370eb {--font-overpass: '__Overpass_Mono_6370eb', '__Overpass_Mono_Fallback_6370eb'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abbad9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_abbad9';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_abbad9 {font-family: '__Inter_abbad9', '__Inter_Fallback_abbad9';font-style: normal
}.__variable_abbad9 {--font-inter: '__Inter_abbad9', '__Inter_Fallback_abbad9'
}

